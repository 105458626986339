import {
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnInit,
    OnChanges, ViewChild, AfterViewInit
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { findByProperty, isDefined, isDefinedNotNull, isNumber, isObject, uniq } from '../utils';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'pro-select',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        PipesModule,
        FlexLayoutModule,
        PromanTextSimpleComponent,
    ],
    standalone: true,
    template: `
        @if (options) {
            <mat-form-field [floatLabel]="config.floatLabel" [attr.data-name]="config.label"
                            [ngClass]="{ 'important-input': config.important && !isDefinedNotNull(value), 'select-hide-arrow': config.hideArrow }"
                            [color]="config.important && !isDefinedNotNull(value) ? 'warn' : 'primary'">
                <mat-select [placeholder]="config.label | translate"
                            [formControl]="control"
                            [required]="config.required"
                            [multiple]="config.multiple"
                            (selectionChange)="handleChange($event)">
                    @if (config.isFlags && tmpVal) {
                        <mat-select-trigger>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div style="height: 15px;">
                                    <img src="../../../../../apps/frontend/src/assets/flags/blank.gif"
                                         class="flag flag-{{ (tmpVal.id | lowercase) }}"
                                         alt="" style="position: relative;top: 7px;margin-top: -12px;" />
                                </div>
                                <span>{{ tmpVal[displayKey] }}</span>
                            </div>
                        </mat-select-trigger>
                    }

                    @if (groupBy) {
                        <div>
                            @for (group of getGroups(); track $index) {
                                <mat-optgroup [label]="group">
                                    @for (option of getOptions(group); track $index) {
                                        <mat-option [value]="option">{{ option[displayKey] }}</mat-option>
                                    }
                                </mat-optgroup>
                            }
                        </div>
                    } @else {
                        <div>
                            @if (!config.disableSearch) {
                                <div class="SelectSearchContainer">
                                    <pro-text-simple [value]="criteria"
                                                     [config]="{ label: 'search', debounce: 0, autofocus: true }"
                                                     (onChange)="criteria = $event;"></pro-text-simple>
                                </div>
                            }
                            
                            @if (config.isNone) {
                                <mat-option>-</mat-option>
                            }
                            
                            @for (option of options | proFilter:criteria:config.displayKey; track $index) {
                                <mat-option [value]="option"
                                            [ngClass]="{ 'hasFlags' : config.isFlags }"
                                >
                                    <!--                        [pmOverlay]="{ type: 'text', data: config.enableOverlay ? option[displayKey] : null }"-->
                                    @if (config.isFlags) {
                                        <img src="../../../../../apps/frontend/src/assets/flags/blank.gif"
                                             class="flag flag-{{ (option.id | lowercase) }}" alt="" />
                                    }
                                    
                                    @if (isObject(option)) {
                                        {{ option && option[displayKey] || '-' }}
                                    } @else {
                                        {{ option || '-' }}
                                    }
                                </mat-option>
                            }
                        </div>
                    }
                </mat-select>
            </mat-form-field>
        }
    `,
    styles: [
        ':host { width: 100%; }',
        'mat-form-field { width: 100%; }',
        '.SelectSearchContainer { padding: 4px 16px; box-sizing: border-box; max-width: 100%; height: 38px; }'
    ]
})
export class PromanSelectComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatSelect) selectElement: MatSelect;
    @Input() options: unknown[];
    @Input() config: {
        label?: string;
        key?: string;
        displayKey?: string;
        groupBy?: string;
        isNone?: boolean;
        required?: boolean;
        important?: boolean;
        multiple?: boolean;
        disableSearch?: boolean;
        enableOverlay?: boolean;
        isFlags?: boolean;
        hideArrow?: boolean;
        autoopen?: boolean;
        floatLabel?: 'always' | 'auto';
        autoSelectSingleOption?: boolean;
    } = { label: 'search' };
    @Input() value: any;
    @Input() disabled: boolean;
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    tmpVal: any;
    groupBy: any;
    displayKey: string;
    criteria: string;

    ngOnChanges(changes: SimpleChanges) {
        const config = changes.config;
        const disabled = changes.disabled;
        const value = changes.value;
        const options = changes.options;

        if (config && config.currentValue && config.currentValue.groupBy) {
            this.groupBy = config.currentValue.groupBy;

        }

        if (isDefinedNotNull(value) && !value.isFirstChange()) {
            this.getTmpValue(value.currentValue, null);

        }

        if (options && !options.isFirstChange()) {
            this.getTmpValue(null, options.currentValue);

        }

        if (this.control && disabled) {

            if (disabled.currentValue) {
                this.control.disable();

            } else {
                this.control.enable();

            }

        }

    }

    ngOnInit() {
        this.getTmpValue(this.value, this.options);
        this.displayKey = this.config.displayKey || 'name';
        setTimeout(() => {
            if (this.config && this.options && this.config.autoSelectSingleOption) {
                if (this.options.length === 1) {
                    this.onChange.emit(this.options[0]);
                }
            }
        })

        if (this.control && this.disabled) this.control.disable();
    }

    ngAfterViewInit() {
        if (this.config?.autoopen) {
            setTimeout(() => {
                if (this.selectElement) this.selectElement.open();
            }, 100);
        }
    }

    getGroups() {
        return uniq(this.options.map((item: any) => item[this.groupBy]));
    }

    getOptions(group: any) {
        return this.options.filter((item: any) => item[this.groupBy] === group);
    }

    handleChange($event: any) {
        if (this.config.multiple && isDefinedNotNull(this.criteria) && this.criteria !== '') {
            const filteredOut = this.tmpVal?.filter((opt: any) => !opt[this.config.displayKey || 'name'].toLowerCase().includes(this.criteria.toLowerCase())) || [];
            const newSet = new Set(filteredOut);
            $event.value.forEach((el: any) => newSet.add(el));
            const newArr = [...newSet];
            this.onChange.emit(this.config.key ? newArr && newArr[this.config.key] : newArr);
        } else {
            this.onChange.emit(isDefinedNotNull(this.config.key) ? $event.value && $event.value[this.config.key] : $event.value);
        }
    }

    getTmpValue(value: any, options: any) {
        const tmpOptions = options || this.options;
        let tmpValue = value || this.value;
        const tmpMultiResult: any = [];
        let result;

        if (isDefinedNotNull(tmpValue) && (tmpOptions && tmpOptions.length)) {
            let propertyToFind: string;
            let valueToFind: any;

            if (this.config.key) {
                propertyToFind = this.config.key;
                valueToFind = tmpValue;

            } else {
                // when options item doesnt contain default (id / name) properties
                propertyToFind = isDefined(tmpOptions[0]['id']) ? 'id' : Object.keys(tmpOptions[0])[0];
                valueToFind = tmpValue[propertyToFind];

            }

            if (this.config.multiple) {

                if (typeof tmpValue === 'string') { // multi table filter conversion
                    tmpValue = this.stringToOptionsArray(tmpValue);
                }

                tmpValue.forEach((_value: any ) => {
                    tmpMultiResult.push(findByProperty(tmpOptions, 'id', _value && (_value[this.config.key || 'id']) || _value));
                });

                result = tmpMultiResult;

            } else {
                result = findByProperty(tmpOptions, propertyToFind, valueToFind);

            }

        }

        this.tmpVal = result;
        this.control.setValue(result);

    }

    stringToOptionsArray(stringValue: string) {
        let values: any = stringValue.split('|');

        values = values.map((value: any) => {
            if (value[0] === '=') value = value.substr(1);

            return value;
        });

        return values.map((value: string) => ({ id: (isNumber(value) ? +value : value) }));

    }

    isDefinedNotNull = (value: any) => isDefinedNotNull(value);

    isObject = (value: unknown) => isObject(value);

}
