import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'proSort' })
export class SortPipe implements PipeTransform {

    transform(array: any[], field: string, direction: string = 'asc'): any[] {

        if (!array) return array;

        console.log(array, field, direction);
        array.sort((a: any, b: any) => {
            let asc: boolean = direction === 'asc';

            if (a[field] < b[field]) {
                return asc ? -1 : 1;

            } else if (a[field] > b[field]) {
                return asc ? 1 : -1;

            } else {
                return 0;

            }

        });
        return array;
    }
}
