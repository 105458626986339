import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class SerialService {
  serialApiAvailable: boolean;
  private port: any; //SerialPort
  reader: ReadableStreamDefaultReader;
  readableStreamClosed: Promise<void>;
  textDecoder: TextDecoderStream;
  portIsOpen: boolean;
  subject$: Subject<string> = new Subject<string>();

  constructor() {
    this.serialApiAvailable = !!(navigator as any).serial;
  }

  async connect() {
    if (!this.serialApiAvailable) return;
    try {
      if (!this.port) {
        console.log('serialAvailable', this.serialApiAvailable);
        this.port = await (navigator as any).serial.requestPort();
        await this.port.open({ baudRate: 9600 }).then(() => this.portIsOpen = true);
        this.textDecoder = new TextDecoderStream();
        this.readableStreamClosed = this.port.readable.pipeTo(this.textDecoder.writable);
      }
    } catch (err) {
      console.error('Failed to open port: ', err);
    }
  }

  getReader() {
    if (!this.serialApiAvailable) return;
    this.reader = this.textDecoder.readable.getReader();
  }

  async read() {
    if (!this.serialApiAvailable) return;
    while (this.port.readable) {
      if (!this.reader) this.getReader();
      try {
        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            console.log('emitting value DONE', value);
            break;
          }
          this.subject$.next(value);
          return value;
        }
      } catch (err) {
        console.error('Failed to read data: ', err);
        break;
      } finally {
        // console.log('releasing lock');
        // this.reader.releaseLock();
      }
    }
  }

  async disconnect() {
    if (!this.serialApiAvailable) return;
    if (this.port) {
      await this.port.close();
      this.reader = null;
      this.portIsOpen = false;
      this.port = null;
    }
  }
}
